import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage9 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 09</h3>
          <h1>Movement Standards</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              Download the iF3 Movement Standards Training document:{" "}
              <a href="https://www.icflo.com/wp-content/uploads/2018/06/IF3-IF100-TO-Movement-Standard-Doc.xlsx">
                IF3 IF100 TO Movement Standard Doc
              </a>
              .
            </p>

            <p>
              Please take time to review this document thoroughly. This is a
              general guide for all iF3 movements. Event specific programming
              will be provided directly by the individual federation or iF3.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/8">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/quiz">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage9
